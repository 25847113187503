import configs from '@/configs'
import types from './types'

export default {
  /**
   * 
   * @param {String} documentTypeName 
   * @returns 
   */
  getDocumentTypeIcon(documentTypeName) {
    if (documentTypeName in types.documentTypes) {
      return types.documentTypes[documentTypeName].icon
    }

    return ''
  },

  /**
   * 
   * @param {String} termName 
   * @returns 
   */
  getTermTypeIcon(termName) {
    termName = termName.toLowerCase()
    if (termName in types.termTypes) {
      return types.termTypes[termName].icon
    }

    return ''
  },
  
  /**
   * Converts string to titlecase
   * 
   * @param {String} strData 
   * @returns 
   */
  titleCase(strData) {
    const sentence = strData.toLowerCase().split(' ')
    
    for (let i = 0; i < sentence.length; i ++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
    }

    return sentence[0]
  },

  // o.documentitemid.parentdocumentitemid.documentitemid ? o.documentitemid.parentdocumentitemid.documentitemid : o.documentitemid.documentitemid,
  // o.documentitemid.documentid.documentid,
  // o.documentitemid.breadcrumb,
  // 6,
  // o.languageid.languageid

  /**
   * Creates a URL from the breadcrumb
   * 
   * @param {Number} id
   * @param {Number} rootId
   * @param {String} breadcrumb
   * @param {Number} connectionTypeId
   * @param {Number} languageId
   * 
   * @returns {String}
   */
  generateUrlFromBreadcrumb(id, rootId, breadcrumb, connectionTypeId, languageId) {
    // case 1
    // [
    //   {"title": "Vachanamrut", "type": "Documents", "subtype": "Scripture", "id": 1, "isleafnode": false}, 
    //   {"title": "Gadhada Madhyam", "type": "Documentitems", "subtype": "Chapter", "id": 7, "isleafnode": false}, 
    //   {"title": "GM-3", "type": "Documentitems", "subtype": "Verse", "id": 228, "isleafnode": true}, 
    //   {"title": "10", "type": "Documentitems", "subtype": "Stanza", "id": 2302, "isleafnode": false}
    // ]
    // result: /app/document/1/documentitem/228?documentitem_focus=2302

    // case 2, no leaf node
    // [
    //   {"title": "Ishwar", "type": "Documents", "subtype": "KnowledgeBase", "id": 3466, "isleafnode": false}, 
    //   {"title": "5 Forms of Bhagwan", "type": "Documentitems", "subtype": "Topic", "id": 105008, "isleafnode": false}, 
    //   {"title": "Chatur Vyuh", "type": "Documentitems", "subtype": "SubTopic", "id": 105011, "isleafnode": false}
    // ]
    // result: /app/document/3466/documentitem/105011
    
    // console.log('generateUrlFromBreadcrumb', id, rootId, breadcrumb, connectionTypeId, languageId)

    const ct = configs.connectionProperties.types

    if (breadcrumb) {
      try {
        breadcrumb = JSON.parse(breadcrumb)

        // Initialize variables
        let leafNodeId = null
        let leafNodeNotSet = false
        
        // Find the leaf node
        const leafNode = breadcrumb.find((bi) => bi.isleafnode)
        
        // Set leafNodeNotSet to true if no leaf node is found
        if (!leafNode) {
          leafNodeNotSet = true
          // If no leaf node, maybe use the last item in the breadcrumb as fallback
          if (breadcrumb.length > 0) {
            leafNodeId = breadcrumb[breadcrumb.length - 1].id
          }
        } else {
          // If leaf node is found
          if (leafNode.id === null) {
            // Find the index of the leaf node
            const leafNodeIndex = breadcrumb.indexOf(leafNode)
            
            if (leafNodeIndex > 0) {
              leafNodeId = breadcrumb[leafNodeIndex - 1].id
            }
          } else {
            leafNodeId = leafNode.id
          }
        }

        if (Number(connectionTypeId) === ct.documentItems) {
          // Get the last item's ID explicitly to avoid undefined
          const lastItemId = breadcrumb[breadcrumb.length - 1].id
          
          // Case 1: Leaf node exists but isn't the last item (need focus parameter)
          if (leafNode && !leafNodeNotSet && breadcrumb.indexOf(leafNode) < breadcrumb.length - 1) {
            return `/app/document/${breadcrumb[0].id}/documentitem/${leafNodeId}?documentitem_focus=${lastItemId}`
          }
          
          // Case 2: No leaf node, or it's the standard case
          return `/app/document/${breadcrumb[0].id}/documentitem/${leafNodeNotSet ? lastItemId : leafNodeId}`
        }
  
        if (Number(connectionTypeId) === ct.documentItemTranslations) {
          // Get the last item's ID explicitly
          const lastItemId = breadcrumb[breadcrumb.length - 1].id
          
          let url = `/app/document/${breadcrumb[0].id}/documentitem/${leafNodeId}?documentitem_focus=${lastItemId}`
        
          if (languageId) { 
            url = `${url}&documentitemtranslation_languageid=${languageId}`
          }
        
          return url
        }

      } catch (err) {
        console.error(`helper.utils.createUrlFromBreadcrumb could not convert (${breadcrumb}) to a JSON ${err}`)
      }
    }
    
    if (Number(connectionTypeId) === ct.definitions) {
      return `/app/definition/${rootId}`
    }
    if (Number(connectionTypeId) === ct.persons) {
      return `/app/person/${rootId}`
    }
    if (Number(connectionTypeId) === ct.documentItems || Number(connectionTypeId) === ct.documentItemTranslations) {
      let url = `/app/document/${rootId}/documentitem/${id}`

      if (languageId) { 
        url = `${url}?documentitemtranslation_languageid=${languageId}`
      }

      return url
    }

    return '#'
  },

  /**
   * 
   * @param {*} connectionTypeId 
   * @param {*} breadcrumb 
   * @param {*} connectionTitle 
   * @returns 
   */
  generateTitleFromBreadcrumb(connectionTypeId, breadcrumb, connectionTitle, separator) {

    if (!breadcrumb) { return connectionTitle || '' }
    const ct = configs.connectionProperties.types

    if (Number(connectionTypeId) === ct.documentItemTranslations || Number(connectionTypeId) === ct.documentItems && breadcrumb) {
      return JSON.parse(breadcrumb).map((b) => b.title).join(separator || '/')
    }

    return connectionTitle
  },

  /**
   * 
   * @param {*} connectionTypeId 
   * @param {*} breadcrumb 
   * @param {*} connectionTitle 
   * @returns 
   */
  generateTitleWithDocumentItemType(connectionTypeId, breadcrumb, connectionTitle, separator) {
    if (!breadcrumb) { return connectionTitle || '' }
    const ct = configs.connectionProperties.types

    if (breadcrumb && (Number(connectionTypeId) === ct.documentItemTranslations || Number(connectionTypeId) === ct.documentItems)) {
      const breadcrumbArray = JSON.parse(breadcrumb)
      const titles = breadcrumbArray.map((b) => {
        if (b === breadcrumbArray[breadcrumbArray.length - 1]) {
          return `${b.subtype}:${b.title?.trim()}`
        }

        return b.title
      })

      return titles.join(separator || '/')
    }

    return connectionTitle
  },
  generateBreadcrumb(breadcrumb) {

  }
}